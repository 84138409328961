export class AppConfig {
    // ######### LOCAL ##########
	// url: string = 'https://api.apilaravel.ml/api/';
    // ######### PROD  ##########
    //url: string = 'https://api.tjagro.gob.mx/api/';
    // ######### LOCALHOST  ##########
    //url: string = 'http://localhost/tja-guerrero-api/public/api/';
    //url: string = 'https://api.tjagro.gob.mx/api/';
    //url: string = 'http://apilocal.tjadgo.ml/api/';
    url: string = 'https://api.tjadurango.gob.mx/api/';
}