import { FuseNavigation } from '@fuse/types';

const dashboardMenu: FuseNavigation = {
    id       : 'applications',
    title    : '',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    children : [
        {
            id       : 'sample',
            title    : 'Dashboard',
            translate: 'Inicio',
            type     : 'item',
            icon     : 'home',
            url      : '/inicio',
            /*badge    : {
                title    : '25',
                translate: 'NAV.SAMPLE.BADGE',
                bg       : '#F44336',
                fg       : '#FFFFFF'
            }*/
        }
    ]
};

const usersPromoventes: FuseNavigation = {
    id      : 'users-promoventes',
    title   : 'Usuarios Promoventes',
    type    : 'item',
    icon    : 'accessibility_new',
    url  : '/users/list/ciudadano',
    children: [
        {
            id   : 'create-promovente',
            title: 'Crear usuario',
            type : 'item',
            url  : '/users/promotors-create'
        },
        {
            id   : 'list-promovente',
            title: 'Listar usuario',
            type : 'item',
            url  : '/users/list/ciudadano'
        }
    ]
};

const userOfficers: FuseNavigation = {
    id      : 'officers',
    title   : 'Gestión de funcionarios',
    type    : 'item',
    icon    : 'supervisor_account',
    url  : '/users/list/empleado',
    children: [
        {
            id   : 'create-promovente',
            title: 'Crear usuario',
            type : 'item',
            url  : '/users/officers-create'
        },
        {
            id   : 'list-promovente',
            title: 'Listar usuario',
            type : 'item',
            url  : '/users/list/empleado'
        }
    ]
};


const usersMenu: FuseNavigation = {
    id      : 'users',
    title   : 'Usuarios',
    type    : 'group',
    icon    : 'emoji_people',
    children: [
        usersPromoventes,
        userOfficers
    ]
}

const expedientMenu: FuseNavigation = {
    id      : 'manager-expediente',
    title   : 'ESTRADOS DIGITALES',
    type    : 'collapsable',
    icon    : 'important_devices',
    children: [
       /* {
            id   : 'create-expediente',
            title: 'Registro de un Bien Mueble',
            type : 'item',
            icon : 'edit',
            url  : '/management/expedient-create'
        },*/
        {
            id   : 'list-expediente',
            title: 'Boletín Jurisdiccional',
            type : 'item',
            icon : 'room_service',
            url  : '/management/expedients'
        },
     
        {
            id   : 'Notificación por Lista',
            title: 'Listado de acuerdos',
            type : 'item',
            icon : 'list_alt',
            url  : '/management/list-agreements'
        }
    ]
}

const movementsMenu: FuseNavigation = {
        
    id      : 'admin-movimientos',
    title   : 'Movimientos',
    type    : 'collapsable',
    icon    : 'swap_horizontal_circle',
    children: [
        {
            id   : 'mov-servidores',
            title: 'Servidores Públicos',
            type : 'item',
            icon : 'supervised_user_circle',
            url  : 'notifications/mail'
        },
        {
            id   : 'mov-unidad',
            title: 'Unidad administrativa',
            type : 'item',
            icon : 'business',
            url  : 'notifications/mail/1'
        },
        {
            id   : 'mov-asignacion',
            title: 'Asignación',
            type : 'item',
            icon : 'how_to_reg',
            url  : 'notifications/mail/2'
        },
        {
            id   : 'mov-reasignacion',
            title: 'Reasignación',
            type : 'item',
            icon : 'supervisor_account',
            url  : 'notifications/mail/3'
        },
        {
            id   : 'mov-baja',
            title: 'Solicitud Baja',
            type : 'item',
            icon : 'transit_enterexit',
            url  : 'notifications/mail/3'
        }
    ]
}

const searchMenu: FuseNavigation = {
    id      : 'search',
    title   : 'Busqueda',
    type    : 'collapsable',
    icon    : 'search',
    children: [
        {
            id   : 'mov-servidores',
            title: 'Servidores Públicos',
            type : 'item',
            icon : 'supervised_user_circle',
            url  : '/notification'
        },
        {
            id   : 'mov-unidad',
            title: 'Unidad administrativa',
            type : 'item',
            icon : 'business',
            url  : '/notification'
        },
        {
            id   : 'mov-asignacion',
            title: 'Asignación',
            type : 'item',
            icon : 'playlist_add_check',
            url  : '/notification/list'
        },
        {
            id   : 'mov-reasignacion',
            title: 'Reasignación',
            type : 'item',
            icon : 'cached',
            url  : '/notification'
        },
        {
            id   : 'mov-baja',
            title: 'Solicitud Baja',
            type : 'item',
            icon : 'domain_disabled',
            url  : '/notification/list'
        }
    ]
}

const reportsMenu: FuseNavigation = {
    id      : 'search',
    title   : 'Reportes',
    type    : 'collapsable',
    icon    : 'format_list_bulleted',
    children: [
        {
            id   : 'rep-bienes',
            title: 'Inventario Bienes Inmuebles',
            type : 'item',
            icon : 'important_devices',
            url  : '/notification'
        },
        {
            id   : 'rep-adquiridos',
            title: 'Bienes Muebles adquiridos',
            type : 'item',
            icon : 'trending_up',
            url  : '/notification'
        },
        {
            id   : 'rep-estado',
            title: 'Bienes Muebles por estado de uso',
            type : 'item',
            icon : 'update',
            url  : '/notification/list'
        },
    ]
}

export const adminMenu: FuseNavigation[] = [
    dashboardMenu,
   //expedientAdminMenu,
    //notificationMenu,
    usersMenu,
    //authoritiesMenu,
];

export const streamMenu: FuseNavigation[] = [
    dashboardMenu,
    expedientMenu,
    //movementsMenu,
    //searchMenu,
    //reportsMenu
];
