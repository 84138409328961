import { Injectable } from '@angular/core';
import { AppConfig } from './app-config.model';
import { HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
//@Injectable()
@Injectable({providedIn: 'root'})
export class AppConfigService {
    private appConfig: AppConfig = new AppConfig();

    getUrlApi() {
        return this.appConfig.url;
    }

    getHeadersJson() {
        return {
            headers: new HttpHeaders()
                        .set('Content-Type', 'application/json' )
                        .set('Authorization', 'Bearer ' + sessionStorage.getItem('tk'))
        };
    }

    getHeadersBlob() {
        return {
            headers: new HttpHeaders()
            .set('Content-Type', 'application/json' )
            .set('Authorization', 'Bearer ' + sessionStorage.getItem('tk')),
            // Ignore this part or  if you want full response you have 
            // to explicitly give as 'boby'as http client by default give res.json()
            observe:'response' as 'body',
           // have to explicitly give as 'blob' or 'json'
            responseType: 'blob' as 'blob'  
        };
    }

    getHeadersMultipart() {
        return {
            headers: new HttpHeaders({                       
                            'Authorization': 'Bearer ' + sessionStorage.getItem('tk')
                        })
        };
    }

    getTokenString() {
        return 'Bearer ' + sessionStorage.getItem('tk');
    }
    
    getCurrentUser(){
        if(sessionStorage.getItem('usr') !== null) {
            return JSON.parse(sessionStorage.getItem('usr'));
        }
    }

    getTypeEmployee(){
        if(sessionStorage.getItem('tyem') !== null) {
            return sessionStorage.getItem('tyem');
        }
    }

    getDepaEmployee(){
        if(sessionStorage.getItem('em') !== null) {
            const employee =  JSON.parse(sessionStorage.getItem('em'));
            return employee.nume;
        }
    }

    getDateFormat(date){
        moment.locale('es-Mx');
        return moment(date).format('DD-MM-YYYY h:mm:ss') //'MMMM Do YYYY, h:mm:ss a'
    }

    formatUneExpediente(identification) {
        let char_split = '';
        if(identification.includes('-')) {
            char_split = '-'
        }else if(identification.includes('/')) {
            char_split = '/'
        }else {
            return 'false'
        }

        const elements = identification.split(char_split);
        
        return elements[0].trim() + char_split + elements[1].trim(); 
    }
}
