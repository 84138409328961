<!-- BOARDS -->
<div id="boards" class="texture-background" fxLayout="column" fxLayoutAlign="start center" fxFlex fusePerfectScrollbar>

    <div class="header pt-44 pt-md-88" fxLayout="row" fxLayoutAlign="center center" fxFlex="0 0 auto" [@animate]="{value:'*',params:{delay:'100ms'}}">
        <div style="background-color: rgb(255, 255, 255,0.9); border-radius: 50%;" class="logo-tri" [@animate]="{value:'*',params:{scale:'0.5'}}">
            <img width="130" src="assets/images/logos/logotri_font_blank.png">
        </div>

        <div class="logo ml-12" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <h1 style="background-color:rgb(255, 255, 255,0.9); border-radius: 25px; padding-bottom: 7px;"><span class="name_title">e</span>.Justicia Administrativa Durango</h1>
        </div>
    </div>

    <!-- BOARD LIST -->
    <section *ngFor="let option of boards" fxFlex="0 0 auto" fxLayout="column wrap" fxLayoutAlign="center center">
        <div style="background-color: rgb(255, 255, 255,0.9);; border-radius: 20px;" class="logo" fxLayout="row wrap" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <mat-icon class="s-24">{{option.icon}}</mat-icon>
            <h2>{{option.name}}</h2>
        </div>
        <div class="board-list" fxFlex="0 0 auto" fxLayout="row wrap" fxLayoutAlign="center center" [@animateStagger]="{value:'50'}">
            <!-- BOARD -->
            <div class="board-list-item {{board.id}}" *ngFor="let board of option.boards" [routerLink]="[board.path]" routerLinkActive="router-link-active" fxLayout="column" fxLayoutAlign="center center" [@animate]="{value:'*',params:{y:'100%'}}">
                <mat-icon class="s-56">{{board.icon}}</mat-icon>
                <div class="board-name">{{board.name}}</div>
            </div>
            <!-- / BOARD -->

            <!-- NEW BOARD BUTTON >
      <div class="board-list-item add-new-board" fxLayout="column" fxLayoutAlign="center center"
           (click)="newBoard()"
           [@animate]="{value:'*',params:{y:'100%'}}">
          <mat-icon class="s-56">add_circle</mat-icon>
          <div class="board-name">Add new board</div>
      </div>
      <!-- / NEW BOARD BUTTON -->

        </div>
        <!-- / BOARD LIST -->
    </section>
</div>
<!-- / BOARDS -->